import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import * as customPropTypes from '@/custom-prop-types';
import { Button } from '@/design-system/atoms/Button';
import { ContentContainer } from '@/design-system/atoms/ContentContainer';
import { Grid, GridColumn } from '@/design-system/atoms/Grid';
import { Image } from '@/design-system/atoms/Image';
import { Text } from '@/design-system/atoms/Text';
import { Theme } from '@/design-system/atoms/Theme';
import { Title } from '@/design-system/atoms/Title';
import IllustrationPlayer from '@/design-system/molecules/IllustrationPlayer';

import styles from './CtaBanner.module.scss';

/**
 The CTA Banner provides emphasis to a specific call-to-action on a page. It stands out amongst other page content and directs the user to important CTAs.
 */
const CtaBanner = ({
    testId,
    theme = Theme.NAME.SPECIALTIES,
    grid,
    title,
    body,
    text,
    cta,
    buttonType,
    media,
    mediaShape,
    illustration,
    image,
    isFullWidth = false,
    className,
    analytics,
    ...props
}) => {
    return (
        <section
            data-testid={testId}
            className={classnames(
                styles['cta-banner'],
                media === 'illustration' &&
                    illustration &&
                    styles[
                        mediaShape === 'free-floating'
                            ? 'cta-banner--illustration-free-floating'
                            : mediaShape === 'circle'
                              ? 'cta-banner--illustration-circle'
                              : 'cta-banner--illustration-square'
                    ],
                media === 'image' &&
                    image &&
                    styles[
                        mediaShape === 'free-floating'
                            ? 'cta-banner--image-free-floating'
                            : mediaShape === 'circle'
                              ? 'cta-banner--image-circle'
                              : 'cta-banner--image-square'
                    ],
                className && styles[className],
            )}
            {...props}
        >
            <ContentContainer isFullWidth={isFullWidth}>
                <Theme
                    name={theme}
                    className={classnames(
                        styles['cta-banner__main'],
                        !isFullWidth && styles[`cta-banner__main--rounded`],
                    )}
                >
                    <ContentContainer isFullWidth={!isFullWidth}>
                        <Grid
                            className={styles['cta-banner__spacing-wrapper']}
                            verticalAlignment="center"
                        >
                            <GridColumn
                                className={styles['cta-banner__primary']}
                                colSpan={{ sm: 4, md: 6, lg: 8 }}
                                colStart={{ sm: 1, lg: 4 }}
                            >
                                <Grid col={{ sm: 4, md: 4, lg: 8 }} verticalAlignment="center">
                                    <GridColumn
                                        colSpan={{ sm: 4, md: 4, lg: 5 }}
                                        className={styles['cta-banner__content']}
                                    >
                                        <div className={styles['cta-banner__title']}>
                                            <Title
                                                tag="h2"
                                                variant={Title.VARIANT.H4}
                                                content={title}
                                                lineClamp={{ sm: 3, lg: 2 }}
                                            />
                                        </div>
                                        {body && (
                                            <div className={styles['cta-banner__body']}>
                                                <Text
                                                    variant={Text.VARIANT.T2}
                                                    content={body}
                                                    lineClamp={{ sm: 3, lg: 2 }}
                                                />
                                            </div>
                                        )}
                                    </GridColumn>
                                    {cta && cta?.href && (
                                        <GridColumn
                                            colSpan={{ sm: 4, md: 4, lg: 3 }}
                                            colStart={{ sm: 1, md: 1, lg: 6 }}
                                            className={styles['cta-banner__button-wrapper']}
                                        >
                                            <Button
                                                className={styles['cta-banner__button']}
                                                href={cta.href}
                                                target={cta.target}
                                                csr={cta.csr}
                                                buttonStyle={
                                                    buttonType === 'primary'
                                                        ? Button.STYLE.PRIMARY
                                                        : Button.STYLE.SECONDARY
                                                }
                                                fullWidth={{ md: true }}
                                                label={cta.label}
                                                ariaLabel={cta.ariaLabel}
                                                analytics={{
                                                    componentName: 'CTA Banner',
                                                    componentTitle: title,
                                                    ...analytics,
                                                }}
                                            />
                                        </GridColumn>
                                    )}
                                </Grid>
                            </GridColumn>
                            <GridColumn
                                className={styles['cta-banner__secondary']}
                                colSpan={{ sm: 4, md: 6, lg: 3 }}
                                colStart={{ sm: 1, lg: 1 }}
                            >
                                {media === 'illustration' && illustration && (
                                    <div className={styles['cta-banner__illustration']}>
                                        {illustration?.scene && illustration?.title && (
                                            <IllustrationPlayer {...illustration} />
                                        )}
                                    </div>
                                )}
                                {media === 'image' && image && (
                                    <div className={styles['cta-banner__image']}>
                                        <Image
                                            {...image}
                                            mobileAspectRatio={Image.ASPECT_RATIO['1:1']}
                                            defaultAspectRatio={Image.ASPECT_RATIO['1:1']}
                                            roundedCorners={true}
                                        />
                                    </div>
                                )}
                            </GridColumn>
                        </Grid>
                    </ContentContainer>
                </Theme>
            </ContentContainer>
        </section>
    );
};

CtaBanner.propTypes = {
    /** Test ID */
    testId: PropTypes.string,
    /** Theme */
    theme: PropTypes.oneOf(Object.values(Theme.NAME)),
    /** Title */
    title: PropTypes.string.isRequired,
    /** Body Text */
    body: PropTypes.string,
    /** Link */
    link: customPropTypes.linkPropType,
    /** Selected Button Type */
    buttonType: PropTypes.oneOf(['primary', 'secondary']),
    /** Media type to display */
    media: PropTypes.oneOf(['image', 'illustration']),
    /** Media Shape */
    mediaShape: PropTypes.oneOf(['free-floating', 'circle', 'square']),
    /** Illustration */
    illustration: PropTypes.object,
    /** Image */
    image: PropTypes.shape(Image.propTypes),
    /**Full Width */
    iFullWidth: PropTypes.bool,
};
export default CtaBanner;
