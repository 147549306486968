import PropTypes from 'prop-types';
import React from 'react';

import * as customPropTypes from '@/custom-prop-types';
import { Theme } from '@/design-system/atoms/Theme';
import { CtaBanner } from '@/design-system/molecules/CtaBanner';

/**
 *
 * The Contact Us Banner is a full width variation of the CTA banner meant to provide an emphasized contact point. It is set to the specialties theme and only allows for the Primay Button style and circle illustrations.
 */
const ContactUsBanner = ({ id, title, description, cta, mediaShape = 'circle', ...props }) => {
    return (
        <CtaBanner
            id={id}
            title={title}
            description={description}
            cta={cta}
            {...props}
            theme={Theme.NAME.SPECIALTIES}
            isFullWidth
            buttonType="primary"
            mediaShape={mediaShape}
            className={'cta-banner--contact-us'}
            analytics={{
                componentName: 'Contact Us Banner',
            }}
        />
    );
};

ContactUsBanner.propTypes = {
    /** Title */
    title: PropTypes.string.isRequired,
    /** Body Text */
    body: PropTypes.string,
    /** Link */
    link: customPropTypes.linkPropType,
    /** Media type to display */
    media: PropTypes.oneOf(['image', 'illustration']),
    /** Media shape */
    mediaShape: PropTypes.oneOf(['free-floating', 'circle']),
    /** Illustration */
    illustration: PropTypes.object,
    /** Image */
    image: PropTypes.shape(Image.propTypes),
};

export default ContactUsBanner;
